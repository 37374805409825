<template>
  <div class="col-12 pt-3 px-3">
    <div class="row m-0 justify-content-center holder">
      <div class="col-12 above my-4">
        <div class="row m-0 justify-content-center">
          <div class="col-auto text-center px-0 pb-3 border_bottom mb-3">
            <div class="card_title">
              STEP {{page}} of {{ totalPages }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>

export default {
  name: 'StepCount',
  props: ['page', 'totalPages']
}
</script>

<style scoped>
.holder {
  border-radius: 35%;
  position: relative;
  z-index: 2;
}
.above {
  z-index: inherit;
}
.border_bottom {
  border-bottom: 1px solid var(--gold-color);
}
.card_title {
  font-family: "quicksand_medium", Sans-serif;
  font-size: 0.8rem;
  font-weight: 600;
  line-height: 1;
  letter-spacing: 4.3px;
}
@media (min-width: 992px) {
  .card_title {
    font-size: 0.9rem;
  }
}
</style>
